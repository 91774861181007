import Vue from "vue";
import VueI18Next from "@panter/vue-i18next";
import i18next from "i18next";

Vue.use(VueI18Next);

const i18n = new VueI18Next(i18next);

const leverage = () => {
  if (
    process.env.VUE_APP_WHITELABEL === "ICMCapital" ||
    process.env.VUE_APP_WHITELABEL === "ICMMENA" ||
    process.env.VUE_APP_WHITELABEL === "GCCBrokers" ||
    process.env.VUE_APP_WHITELABEL === "TradiNext"
  ) {
    return [
      {
        value: 30,
        name: "1:30",
      },
      {
        value: 50,
        name: "1:50",
      },
      {
        value: 100,
        name: "1:100",
      },
      {
        value: 150,
        name: "1:150",
      },
      {
        value: 200,
        name: "1:200",
      },
    ];
  }
  if (process.env.VUE_APP_WHITELABEL === "ICMEU") {
    return [
      {
        value: 50,
        name: "1:50",
      },
      {
        value: 100,
        name: "1:100",
      },
      {
        value: 150,
        name: "1:150",
      },
      {
        value: 200,
        name: "1:200",
      },
    ];
  }
  if (process.env.VUE_APP_WHITELABEL === "ICMTrader") {
    return [
      {
        value: 25,
        name: "1:25",
      },
      {
        value: 50,
        name: "1:50",
      },
      {
        value: 100,
        name: "1:100",
      },
      {
        value: 150,
        name: "1:150",
      },
      {
        value: 200,
        name: "1:200",
      },
      {
        value: 300,
        name: "1:300",
      },
      {
        value: 400,
        name: "1:400",
      },
    ];
  }
};

const depositAmounts = () => {
  if (
    process.env.VUE_APP_WHITELABEL === "ICMVC" ||
    process.env.VUE_APP_WHITELABEL === "ICMMU"
  ) {
    return [
      {
        id: 0,
        name: "1,000",
      },
      {
        id: 1,
        name: "5,000",
      },
      {
        id: 2,
        name: "10,000",
      },
      {
        id: 3,
        name: "25,000",
      },
      {
        id: 4,
        name: "50,000",
      },
      {
        id: 5,
        name: "100,000",
      },
      {
        id: 6,
        name: "500,000",
      },
      {
        id: 7,
        name: "1,000,000",
      },
      {
        id: 8,
        name: "5,000,000",
      },
    ];
  }
  if (process.env.VUE_APP_WHITELABEL === "Blackstone") {
    return [
      {
        id: 1,
        name: "5,000",
      },
      {
        id: 3,
        name: "25,000",
      },
      {
        id: 5,
        name: "100,000",
      },
    ];
  }
  if (
    process.env.VUE_APP_WHITELABEL === "ICMEU" ||
    process.env.VUE_APP_WHITELABEL === "ICMTrader"
  ) {
    return [
      {
        id: 1,
        name: "5,000",
      },
      {
        id: 2,
        name: "10,000",
      },
      {
        id: 3,
        name: "25,000",
      },
      {
        id: 4,
        name: "50,000",
      },
      {
        id: 5,
        name: "100,000",
      },
      {
        id: 6,
        name: "500,000",
      },
      {
        id: 7,
        name: "1,000,000",
      },
      {
        id: 8,
        name: "5,000,000",
      },
    ];
  } else {
    return [
      {
        id: 0,
        name: "1,000",
      },
      {
        id: 1,
        name: "5,000",
      },
      {
        id: 2,
        name: "10,000",
      },
      {
        id: 3,
        name: "25,000",
      },
      {
        id: 4,
        name: "50,000",
      },
      {
        id: 5,
        name: "100,000",
      },
      {
        id: 6,
        name: "500,000",
      },
      {
        id: 7,
        name: "1,000,000",
      },
      {
        id: 8,
        name: "5,000,000",
      },
    ];
  }
};

const accountTypes = () => {
  const accountTypeConfig = {
    PolarisMarkets: [
      {
        value: "MARGIN",
        label: "Position Based",
      },
      {
        value: "CASH",
        label: "Net Based",
      },
    ],
    ICMVC: ["Direct", "ICM Zero", "ICM Micro"],
    ICMMU: ["Direct", "ICM Zero", "ICM Micro"],
    ICMEU: ["Direct"],
    ICMTrader: ["Direct"],
    ICMAP: ["Direct"],
    VCGMarkets: ["Standard", "Premium"],
    GCCBrokers: ["Pro", "Standard"],
    FXViewEU: ["Standard"],
    FXViewGlobal: ["Standard"],
    AAAFXGlobal: ["Standard"],
    AAAFXEU: ["Standard"],
  };

  return (
    accountTypeConfig[process.env.VUE_APP_WHITELABEL] || ["Direct", "ICM Zero"]
  );
};

const accountTypesOTM = () => {
  return ["OTM Standard", "OTM Executive", "OTM Professional"];
};

const passwordPattern = () => {
  if (
    process.env.VUE_APP_WHITELABEL === "ICMVC" ||
    process.env.VUE_APP_WHITELABEL === "ICMAP" ||
    process.env.VUE_APP_WHITELABEL === "ICMMU" ||
    process.env.VUE_APP_WHITELABEL === "ICMCapital" ||
    process.env.VUE_APP_WHITELABEL === "OTM" ||
    process.env.VUE_APP_WHITELABEL === "ICMMENA" ||
    process.env.VUE_APP_WHITELABEL === "ICMTrader"
  ) {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$_!#%^*?&-])[A-Za-z\d@$_!#%^*?&-]{8,15}$/;
  } else if (
    process.env.VUE_APP_WHITELABEL === "GCCBrokers" ||
    process.env.VUE_APP_WHITELABEL === "VCGMarkets" ||
    process.env.VUE_APP_WHITELABEL === "PolarisMarkets" ||
    process.env.VUE_APP_WHITELABEL === "FXViewEU" ||
    process.env.VUE_APP_WHITELABEL === "FXViewGlobal" ||
    process.env.VUE_APP_WHITELABEL === "AAAFXGlobal" ||
    process.env.VUE_APP_WHITELABEL === "AAAFXEU"
  ) {
    // this regex requires min 8 characters 1 uppercase letter 1 lowercase 1 number and 1 symbol
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$_.!#%^*?&-])[A-Za-z\d@$_.!#%^*?&-]{8,15}$/;
  } else {
    return /^(?=.*[A-Z])(?=.*[0-9]).{8,15}$/;
  }
};

const languageOptions = () => {
  if (process.env.VUE_APP_WHITELABEL === "VCGMarkets") {
    return ["en", "ar", "ku"];
  }
  if (
    process.env.VUE_APP_WHITELABEL === "ICMTrader" ||
    process.env.VUE_APP_WHITELABEL === "ICMEU" ||
    process.env.VUE_APP_WHITELABEL === "ICMAP"
  ) {
    return ["en", "ar", "fa", "zh-Hans", "zh-Hant", "nl", "ru", "es"];
  } else if (process.env.VUE_APP_WHITELABEL === "ICMMU") {
    return [
      "en",
      "ar",
      "fa",
      "zh-Hans",
      "zh-Hant",
      "nl",
      "ru",
      "es",
      "id",
      "ms",
      "vi",
    ];
  } else if (
    process.env.VUE_APP_WHITELABEL === "ICMCapital" ||
    process.env.VUE_APP_WHITELABEL === "GCCBrokers" ||
    process.env.VUE_APP_WHITELABEL === "TradiNext"
  ) {
    return ["en", "ar", "zh-Hans", "zh-Hant", "es", "pt", "th"];
  } else if (process.env.VUE_APP_WHITELABEL === "Promena") {
    return ["en", "ar", "zh-Hans", "zh-Hant", "es", "pt", "de"];
  } else if (process.env.VUE_APP_WHITELABEL === "OneTradingMarkets") {
    return ["en", "ar"];
  } else if (process.env.VUE_APP_WHITELABEL === "EagleGlobalMarkets") {
    return ["en"];
  } else if (process.env.VUE_APP_WHITELABEL === "OrbitInvest") {
    return ["en"];
  } else if (process.env.VUE_APP_WHITELABEL === "TradeCoreUK") {
    return ["en"];
  } else if (process.env.VUE_APP_WHITELABEL === "ICMMENA") {
    return ["en", "ar"];
  } else {
    return [
      "en",
      "ar",
      "fa",
      "zh-Hans",
      "zh-Hant",
      "ko",
      "nl",
      "ru",
      "es",
      "th",
      "id",
      "ms",
      "vi",
    ];
  }
};

export {
  leverage,
  depositAmounts,
  accountTypes,
  accountTypesOTM,
  passwordPattern,
  languageOptions,
};
