<template>
  <div class="user-menu-vue">
    <div v-click-outside="hide" class="relative inline-block text-left w-50">
      <div :class="navigation ? 'mt-2' : 'border-l  mx-2'">
        <div
          v-if="navigation"
          class="small-screen-usermenu cursor-pointer mr-3"
          @click="showMenu = !showMenu"
        >
          <svg
            fill="none"
            height="30"
            viewBox="0 0 24 24"
            width="30"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.35667 18.4489C6.68824 17.131 9.32175 16.4404 12 16.4444C14.7778 16.4444 17.3856 17.1722 19.6433 18.4489M15.3333 9.77778C15.3333 10.6618 14.9821 11.5097 14.357 12.1348C13.7319 12.7599 12.8841 13.1111 12 13.1111C11.1159 13.1111 10.2681 12.7599 9.64298 12.1348C9.01786 11.5097 8.66667 10.6618 8.66667 9.77778C8.66667 8.89372 9.01786 8.04588 9.64298 7.42076C10.2681 6.79563 11.1159 6.44444 12 6.44444C12.8841 6.44444 13.7319 6.79563 14.357 7.42076C14.9821 8.04588 15.3333 8.89372 15.3333 9.77778ZM22 12C22 13.3132 21.7413 14.6136 21.2388 15.8268C20.7362 17.0401 19.9997 18.1425 19.0711 19.0711C18.1425 19.9997 17.0401 20.7362 15.8268 21.2388C14.6136 21.7413 13.3132 22 12 22C10.6868 22 9.38642 21.7413 8.17317 21.2388C6.95991 20.7362 5.85752 19.9997 4.92893 19.0711C4.00035 18.1425 3.26375 17.0401 2.7612 15.8268C2.25866 14.6136 2 13.3132 2 12C2 9.34784 3.05357 6.8043 4.92893 4.92893C6.8043 3.05357 9.34784 2 12 2C14.6522 2 17.1957 3.05357 19.0711 4.92893C20.9464 6.8043 22 9.34784 22 12Z"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.16667"
            />
          </svg>
        </div>
        <span v-else class="rounded-md shadow-sm">
          <button
            id="options-menu"
            :class="showMenu ? 'bg-gray-100' : 'bg-white'"
            aria-expanded="true"
            aria-haspopup="true"
            class="inline-flex justify-center w-full rounded-md px-4 py-2 text-sm leading-5 font-normal text-gray-700 hover:text-gray-500 focus:outline-none transition ease-in-out duration-150"
            type="button"
            @click="showMenu = !showMenu"
          >
            <svg
              class="me-3 my-auto"
              fill="none"
              height="15"
              viewBox="0 0 13 15"
              width="13"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.55859 9.0625C7.76562 9.0625 7.41016 9.5 6.125 9.5C4.8125 9.5 4.45703 9.0625 3.66406 9.0625C1.64062 9.0625 0 10.7305 0 12.7539V13.4375C0 14.1758 0.574219 14.75 1.3125 14.75H10.9375C11.6484 14.75 12.25 14.1758 12.25 13.4375V12.7539C12.25 10.7305 10.582 9.0625 8.55859 9.0625ZM10.9375 13.4375H1.3125V12.7539C1.3125 11.4414 2.35156 10.375 3.66406 10.375C4.07422 10.375 4.70312 10.8125 6.125 10.8125C7.51953 10.8125 8.14844 10.375 8.55859 10.375C9.87109 10.375 10.9375 11.4414 10.9375 12.7539V13.4375ZM6.125 8.625C8.28516 8.625 10.0625 6.875 10.0625 4.6875C10.0625 2.52734 8.28516 0.75 6.125 0.75C3.9375 0.75 2.1875 2.52734 2.1875 4.6875C2.1875 6.875 3.9375 8.625 6.125 8.625ZM6.125 2.0625C7.54688 2.0625 8.75 3.26562 8.75 4.6875C8.75 6.13672 7.54688 7.3125 6.125 7.3125C4.67578 7.3125 3.5 6.13672 3.5 4.6875C3.5 3.26562 4.67578 2.0625 6.125 2.0625Z"
                fill="#889AB6"
              />
            </svg>
            <div
              style="display: flex; flex-direction: column; text-align: initial"
            >
              <span
                v-if="
                  whitelabel === 'ICMCapital' &&
                  get_user_data.extra.client_type &&
                  get_user_data.extra.client_type === 'company'
                "
              >
                {{ get_profile.company_name }}
              </span>
              <span
                v-else-if="
                  get_profile.first_name && get_profile.first_name.length > 0
                "
              >
                {{ get_profile.first_name + " " + get_profile.last_name }}
              </span>
              <span v-else>
                {{ get_profile.username }}
              </span>
              <span
                v-if="get_profile.state === 'Limited'"
                style="color: #65676a; font-size: 12px; line-height: 16px"
              >
                Your account is in limited state
              </span>
            </div>
            <!-- Heroicon name: chevron-down -->
            <svg
              class="ms-8 h-4 w-5 h-6 my-auto text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clip-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                fill-rule="evenodd"
              />
            </svg>
          </button>
        </span>
      </div>
      <div>
        <div
          v-if="showMenu"
          class="user-menu-content origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg z-10"
        >
          <div
            aria-labelledby="options-menu"
            aria-orientation="vertical"
            class="rounded-md bg-white shadow-xs p-2"
            role="menu"
          >
            <div
              class="text-sm text-gray-500 flex p-2 hover:bg-gray-100 rounded-md"
            >
              <router-link
                v-if="get_profile.profile && get_profile.profile.type === 'IB'"
                class="flex"
                to="/ib/personal-details"
                @click="showMenu = false"
              >
                <svg
                  class="me-4 my-auto"
                  fill="none"
                  height="16"
                  viewBox="0 0 16 16"
                  width="16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.64967 12.5142C4.28177 11.5917 6.12523 11.1083 8 11.1111C9.94444 11.1111 11.7699 11.6206 13.3503 12.5142M10.3333 6.44444C10.3333 7.06328 10.0875 7.65678 9.64992 8.09436C9.21233 8.53195 8.61884 8.77778 8 8.77778C7.38116 8.77778 6.78767 8.53195 6.35008 8.09436C5.9125 7.65678 5.66667 7.06328 5.66667 6.44444C5.66667 5.82561 5.9125 5.23211 6.35008 4.79453C6.78767 4.35694 7.38116 4.11111 8 4.11111C8.61884 4.11111 9.21233 4.35694 9.64992 4.79453C10.0875 5.23211 10.3333 5.82561 10.3333 6.44444ZM15 8C15 8.91925 14.8189 9.82951 14.4672 10.6788C14.1154 11.5281 13.5998 12.2997 12.9497 12.9497C12.2997 13.5998 11.5281 14.1154 10.6788 14.4672C9.82951 14.8189 8.91925 15 8 15C7.08075 15 6.1705 14.8189 5.32122 14.4672C4.47194 14.1154 3.70026 13.5998 3.05025 12.9497C2.40024 12.2997 1.88463 11.5281 1.53284 10.6788C1.18106 9.82951 1 8.91925 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8Z"
                    stroke="#889AB6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.16667"
                  />
                </svg>
                <span v-if="isIB">
                  {{ $t("profile-details") }}
                </span>
                <span v-else>
                  {{
                    $t("personal-information-2433db5a329ea473271a3cd8c013362e")
                  }}
                </span>
              </router-link>
              <router-link v-else class="flex" to="/personal-information">
                <span class="flex" @click="showMenu = false">
                  <svg
                    class="me-4 my-auto"
                    fill="none"
                    height="16"
                    viewBox="0 0 16 16"
                    width="16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.64967 12.5142C4.28177 11.5917 6.12523 11.1083 8 11.1111C9.94444 11.1111 11.7699 11.6206 13.3503 12.5142M10.3333 6.44444C10.3333 7.06328 10.0875 7.65678 9.64992 8.09436C9.21233 8.53195 8.61884 8.77778 8 8.77778C7.38116 8.77778 6.78767 8.53195 6.35008 8.09436C5.9125 7.65678 5.66667 7.06328 5.66667 6.44444C5.66667 5.82561 5.9125 5.23211 6.35008 4.79453C6.78767 4.35694 7.38116 4.11111 8 4.11111C8.61884 4.11111 9.21233 4.35694 9.64992 4.79453C10.0875 5.23211 10.3333 5.82561 10.3333 6.44444ZM15 8C15 8.91925 14.8189 9.82951 14.4672 10.6788C14.1154 11.5281 13.5998 12.2997 12.9497 12.9497C12.2997 13.5998 11.5281 14.1154 10.6788 14.4672C9.82951 14.8189 8.91925 15 8 15C7.08075 15 6.1705 14.8189 5.32122 14.4672C4.47194 14.1154 3.70026 13.5998 3.05025 12.9497C2.40024 12.2997 1.88463 11.5281 1.53284 10.6788C1.18106 9.82951 1 8.91925 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8Z"
                      stroke="#889AB6"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.16667"
                    />
                  </svg>
                  {{
                    $t("personal-information-2433db5a329ea473271a3cd8c013362e")
                  }}
                </span>
              </router-link>
            </div>
            <div
              class="text-sm text-gray-500 flex p-2 hover:bg-gray-100 rounded-md cursor-pointer"
              @click="(showChangePasswordModal = true), (showMenu = false)"
            >
              <svg
                class="me-4 my-auto"
                fill="none"
                height="16"
                viewBox="0 0 14 16"
                width="14"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 10.3333V11.8889V10.3333ZM2.5 15H11.5C11.8978 15 12.2794 14.8361 12.5607 14.5444C12.842 14.2527 13 13.857 13 13.4444V8.77778C13 8.36522 12.842 7.96956 12.5607 7.67783C12.2794 7.38611 11.8978 7.22222 11.5 7.22222H2.5C2.10218 7.22222 1.72064 7.38611 1.43934 7.67783C1.15804 7.96956 1 8.36522 1 8.77778V13.4444C1 13.857 1.15804 14.2527 1.43934 14.5444C1.72064 14.8361 2.10218 15 2.5 15ZM10 7.22222V4.11111C10 3.28599 9.68393 2.49467 9.12132 1.91122C8.55871 1.32778 7.79565 1 7 1C6.20435 1 5.44129 1.32778 4.87868 1.91122C4.31607 2.49467 4 3.28599 4 4.11111V7.22222H10Z"
                  stroke="#889AB6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
              </svg>
              {{ $t("change-password-0b39c5aca15b84b1ad53a94d6b3feb78") }}
            </div>
            <div
              class="text-sm text-gray-500 flex p-2 hover:bg-gray-100 rounded-md cursor-pointer"
              @click="logout"
            >
              <svg
                class="me-4 my-auto"
                fill="none"
                height="14"
                viewBox="0 0 16 14"
                width="16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.77778 10.1112V10.8889C8.77778 11.5078 8.53195 12.1013 8.09436 12.5389C7.65678 12.9764 7.06328 13.2223 6.44444 13.2223H3.33333C2.71449 13.2223 2.121 12.9764 1.68342 12.5389C1.24583 12.1013 1 11.5078 1 10.8889V3.11117C1 2.49233 1.24583 1.89883 1.68342 1.46125C2.121 1.02366 2.71449 0.777832 3.33333 0.777832H6.44444C7.06328 0.777832 7.65678 1.02366 8.09436 1.46125C8.53195 1.89883 8.77778 2.49233 8.77778 3.11117V3.88894M11.8889 10.1112L15 7.00005L11.8889 10.1112ZM15 7.00005L11.8889 3.88894L15 7.00005ZM15 7.00005H4.11111H15Z"
                  stroke="#889AB6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
              </svg>
              {{ $t("Logout") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <change-password
      v-if="showChangePasswordModal"
      @close="close"
    ></change-password>
  </div>
</template>
<script>
import ChangePassword from "@/views/documents/profile-details/ChangePassword";
import { mapGetters } from "vuex";
import ClickOutside from "vue-click-outside";

export default {
  name: "UserMenu",
  components: {
    ChangePassword,
  },
  data() {
    return {
      whitelabel: process.env.VUE_APP_WHITELABEL,
      showMenu: false,
      showChangePasswordModal: false,
      isIB: false,
    };
  },
  props: {
    navigation: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
  },
  computed: {
    ...mapGetters(["get_profile", "get_user_data"]),
  },
  created() {
    if (this.$route.path.includes("/ib")) {
      this.isIB = true;
    }
  },
  methods: {
    close() {
      this.showChangePasswordModal = false;
    },
    hide() {
      this.showMenu = false;
    },
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        const url = process.env.VUE_APP_API_URL.includes("dev.")
          ? "https://dev-website.anttix.app/en/auth/login"
          : "https://vcgmarkets.com/en/auth/login";

        if (this.whitelabel === "VCGMarkets") {
          //   redirect to external login
          return window.open(url, "_self");
        }
        if (this.$route.path.includes("/ib")) {
          this.$router.push("/ib/login");
        } else {
          this.$router.push("/auth/login");
        }
      });
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>
